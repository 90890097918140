//Function to extract transfrom3d values from a element
export const getMatrix = (el) => {
  const values = el.style.transform.split(/\w+\(|\);?/);
  const transform = values[1].split(/,\s?/g).map((numStr) => parseInt(numStr));
  return {
    x: transform[0],
    y: transform[1],
    z: transform[2],
  };
};

export function extractNumber(string) {
  return string.replace(/\D/g, "");
}
