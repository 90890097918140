import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import path1 from "./svgElements/birdsPath1";
import { map } from "./map";
// import swansAnimation from "./svgElements/swansAnimationLayer";
import swanFrame1 from "../assets/images/keyframes/swans/swans01.svg";
import swanFrame2 from "../assets/images/keyframes/swans/swans02.svg";
import swanFrame3 from "../assets/images/keyframes/swans/swans03.svg";
import swanFrame4 from "../assets/images/keyframes/swans/swans04.svg";
import swanFrame5 from "../assets/images/keyframes/swans/swans05.svg";
import swanFrame6 from "../assets/images/keyframes/swans/swans06.svg";
import swanFrame7 from "../assets/images/keyframes/swans/swans07.svg";
import swanFrame8 from "../assets/images/keyframes/swans/swans08.svg";
import waterFrame1 from "../assets/images/keyframes/water/water01.svg";
import waterFrame2 from "../assets/images/keyframes/water/water02.svg";
import waterFrame3 from "../assets/images/keyframes/water/water03.svg";
import waterFrame4 from "../assets/images/keyframes/water/water04.svg";
import waterFrame5 from "../assets/images/keyframes/water/water05.svg";
import waterFrame6 from "../assets/images/keyframes/water/water06.svg";
import waterFrame7 from "../assets/images/keyframes/water/water07.svg";
import waterFrame8 from "../assets/images/keyframes/water/water08.svg";

let swanFrames = [swanFrame1, swanFrame2, swanFrame3, swanFrame4, swanFrame4, swanFrame5, swanFrame6, swanFrame7, swanFrame8];
let waterFrames = [waterFrame1, waterFrame2, waterFrame3, waterFrame4, waterFrame5, waterFrame6, waterFrame7, waterFrame8];
const swansAnimation = `<svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlns:xlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 1920 1080'
    style='enable-background:new 0 0 1919.4 1081.1;'
    xml:space='preserve'
    >
    <g id="water">
      <image x="940" y="650" id="waterImage"
        width="100" height="420"
          href=${waterFrames[0]}
      />
    </g>
    <g id="swan">
        <image x="940" y="820" id="swanImage"
          width="100" height="100"
              href=${swanFrames[0]}
          />
    </g>
    <rect x="0" fill="none" class="st0" width="1920" height="1080"/>
    </svg>`;

const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
const screenHeight = window.screen.height;
const screenWidth = window.screen.width;
svg.setAttribute("width", "100%");
svg.setAttribute("height", "100%");
svg.setAttribute("class", "svgLineDOM");
svg.innerHTML = path1;
document.body.appendChild(svg);
const w = 3840,
  h = 2160;

const swansSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
swansSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
swansSvg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
swansSvg.innerHTML = swansAnimation;

// calculate the edges of the image, in coordinate space
const southWest = map.unproject([0, h], map.getMaxZoom());
const northEast = map.unproject([w, 0], map.getMaxZoom());
let bounds = new L.LatLngBounds(southWest, northEast);

let animationOverlay = L.svgOverlay(swansSvg, bounds).addTo(map);

gsap.registerPlugin(MotionPathPlugin);
gsap.set(".birds", { xPercent: -50, yPercent: -50, transformOrigin: "50% 50%" });
gsap.set(".birds2", { xPercent: -50, yPercent: -50, transformOrigin: "50% 50%" });

var tl = gsap.timeline({ repeat: -1, repeatDelay: 5, defaults: { duration: 15 } });

tl.to(".birds", {
  motionPath: { path: "#path1", align: "#path1", type: "linear", autoRotate: true },
  delay: 2,
}).to(".birds2", {
  motionPath: { path: "#path2", align: "#path2", type: "linear", autoRotate: true },
});

let swanonSvg = document.querySelector("#swanImage");
let waterSvg = document.querySelector("#waterImage");

var index = 0;
setInterval(function () {
  swanonSvg.setAttribute("href", swanFrames[index]);
  waterSvg.setAttribute("href", waterFrames[index]);
  index += 1;
  if (index === swanFrames.length - 1) index = 0;
}, 200);
