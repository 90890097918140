//DOM Elements
const openSidebarUI = document.querySelector("#openSidebar");
const closeSidebarUI = document.querySelector("#closeSidebar");
const sidebarUI = document.querySelector("#sidebar");
const closeIcon = document.querySelector("#sidebar .close-sidebar-icon")
const scrollMarker = document.querySelector("#sidebar .scroll-marker")
const sidebarUlUI = document.querySelector("#sidebar-ul");
const singleMarkerUI = document.querySelector(".single-marker");
import halfcircle from "../assets/images/icons/sidebar/half-circle.svg";
import leftstraight from "../assets/images/icons/sidebar/left-straight.svg";
import minus from "../assets/images/icons/sidebar/minus.svg";
import right from "../assets/images/icons/sidebar/right.svg";
import tram from "../assets/images/icons/sidebar/tram.svg";
import opensidebar from "../assets/images/icons/sidebar/open-sidebar.svg";
import slashedcircle from "../assets/images/icons/sidebar/slashed-circle.png";
import crosshair from "../assets/images/icons/sidebar/crosshair.svg";
import { extractNumber } from "./services";
import { openBottomOverlay, closeBottomOverlay, bottomOverlay } from "./bottomOverlay";
var flatendArr = {};
flatendArr.length = window.interactiveGraphicVBZTexts.length;

for (let i = 0; i < window.interactiveGraphicVBZTexts.length; i++) {
  const key = window.interactiveGraphicVBZTexts[i].key;
  const value = window.interactiveGraphicVBZTexts[i].value;
  // flatendArr[data[i].key] = data[i].value;
  flatendArr[`${key}`] = value;
}

let imagesObj = {
  slashedcircle: slashedcircle,
  halfcircle: halfcircle,
  leftstraight: leftstraight,
  minus: minus,
  right: right,
  tram: tram,
  opensidebar: opensidebar,
  crosshair: crosshair,
};

(function () {
  //Open sidebar on click
  openSidebarUI.addEventListener("click", openSidebar);

  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if (width < 700) {
    console.log("screen smaller than 700px");
    sidebarUlUI.appendChild(openSidebarUI);
  }
})();

//Open sidebar function
function openSidebar(e) {
  e.stopPropagation();
  // debugger;
  if (!sidebarUI.classList.contains("sidebar__open")) {
    sidebarUI.classList.add("sidebar__open");
    closeIcon.classList.remove("visible");
    scrollMarker.classList.remove("visible");
  }
  if (document.body.scrollWidth < 1000) {
    closeBottomOverlay();
  }
  setTimeout(() => {
    closeIcon.classList.add("visible");
    if (singleMarkerUI.offsetHeight > sidebarUI.offsetHeight) {
      scrollMarker.classList.add("visible");
    } else {
      scrollMarker.classList.remove("visible");
    }
  }, 400);
}
//Close sidebar
closeSidebarUI.addEventListener("click", function (e) {
  sidebarUlUI.style.display = "flex";
  singleMarkerUI.style.display = "none";
  sidebarUI.classList.remove("sidebar__open");
  sidebarUI.classList.remove("_extended");
  closeIcon.classList.remove("visible");
  scrollMarker.classList.remove("visible");
});

//Add default translations
const textToTranslate = sidebarUlUI.querySelectorAll("h3");
textToTranslate.forEach(function (el) {
  const dataValue = el.getAttribute("data-name");
  el.innerHTML = flatendArr[dataValue];
});

const mainSidebarIcons = document.querySelectorAll(".interactive");
const singleMarkerIcon = document.querySelector(".single-marker-heading-icon");
mainSidebarIcons.forEach(function (icon) {
  const dataValue = icon.getAttribute("data-text");
  let links = linksForLegendMarkers(extractNumber(dataValue));
  if (flatendArr[dataValue] !== undefined) {
    icon.addEventListener("click", function (e) {
      singleMarkerUI.children[2].innerHTML = "";
      const dataValueHeading = e.currentTarget.children[1].children[0].getAttribute("data-name");
      sidebarUlUI.style.display = "none";
      singleMarkerUI.style.display = "flex";
      singleMarkerUI.children[1].innerHTML = flatendArr[dataValue];
      singleMarkerUI.children[0].children[1].innerHTML = flatendArr[dataValueHeading];
      singleMarkerUI.children[2].appendChild(links);
      const dataSrc = e.currentTarget.getAttribute("data-image-source");
      singleMarkerIcon.children[0].src = imagesObj[`${dataSrc}`];
      sidebarUI.classList.add("_extended");
      openSidebar(e);
    });
  } else {
    icon.addEventListener("click", openSidebar);
  }
});
function setMarkerSidebar(markerDATA) {
  sidebarUI.classList.add("_extended");
  singleMarkerUI.children[2].innerHTML = "";
  singleMarkerUI.children[1].innerHTML = markerDATA.desc;
  singleMarkerUI.children[0].children[1].innerHTML = markerDATA.title;
  const imgSource = markerDATA.imgSrc;
  singleMarkerUI.children[0].children[0].children[0].src = imagesObj[`${markerDATA.imgSrc}`];
  singleMarkerUI.children[2].appendChild(markerDATA.links);
  sidebarUlUI.style.display = "none";
  singleMarkerUI.style.display = "flex";
}

function linksForLegendMarkers(id) {
  let legendMarkerLinks = [];
  for (let i = 0; i < flatendArr.length; i++) {
    let linkText = flatendArr[`LEGEND_${id}_LINK_${i}_TEXT`];
    let linkURL = flatendArr[`LEGEND_${id}_LINK_${i}_URL`];
    if (linkText && linkURL) legendMarkerLinks.push({ text: linkText, url: linkURL });
  }
  let div = document.createElement("div");
  div.classList.add("single-marker-links-inner");
  legendMarkerLinks.forEach(function (el) {
    const link = document.createElement("a");
    link.classList.add("single-marker-single-link");
    link.href = el.url.replace(/<\/?p>/gi, "");
    link.target = "_top";
    link.innerHTML = el.text;
    div.appendChild(link);
  });
  return div;
}
export { flatendArr, openSidebar, setMarkerSidebar };
