const bottomOvClose = document.querySelector(".bottom-overlay-close-icon");
const bottomOverlay = document.querySelector(".bottom-overlay");
bottomOverlay.style.opacity = "1";

function closeBottomOverlay() {
  bottomOverlay.classList.remove("open");
}
function openBottomOverlay(data) {
  bottomOverlay.children[1].children[0].innerHTML = data.title;
  bottomOverlay.children[1].children[1].innerHTML = data.text;
  //   console.log(data);
  bottomOverlay.classList.add("open");
}

bottomOvClose.addEventListener("click", function () {
  closeBottomOverlay();
});

export { openBottomOverlay, closeBottomOverlay, bottomOverlay };
