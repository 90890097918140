//css imports
import "./src/styles/spinner.css";
import "./style.css";
import "./src/styles/sidebar.css";
import "./src/styles/map.css";
import "./src/styles/heatmap.css";
import "./src/styles/utility.css";
import "./src/styles/animations.css";
import "./src/styles/bottomOverlay.css";
//js imports
import "./src/js/map";
import "./src/js/sidebar";
import "./src/js/markers";
import "./src/js/bottomOverlay";
import "./src/js/animations";
const spinner = document.querySelector(".spinner");
setTimeout(function () {
  spinner.remove();
}, 300);
