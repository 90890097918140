const iconsLayer = `
<g id="ICONS">
<g class="">
  <circle class="cls-1"  cx="666" cy="510" r="26.98"/>
  <path class="cls-2" d="M682.2 501.18c-.17-1.07-.38-2.27-2.16-2.27h-9.78l4.87-3.68-8.49-4.87-8.49 4.87 4.87 3.68h-14.61v1.92h17.46a.68.68 0 01.77.77v5.46a.68.68 0 01-.77.77h-17.46v7.6h35.74zm-17.2-2.27l-4.69-3.5 6.29-3.62 6.29 3.62-4.68 3.5zm15.48 9.22h-4.63a.53.53 0 01-.59-.6v-6.11a.52.52 0 01.59-.59h3.74c.27 0 .49.17.65.53l.83 6.05c.05.48-.15.72-.55.72zM648.41 518.44h8.49v5.17h-8.49zM684.39 518.44h-8.01v5.17h8.48v-1.72l-.47-3.45z"/>
  <circle class="cls-2" cx="662.16" cy="520.34" r="3.53"/>
  <circle class="cls-2" cx="671.05" cy="520.31" r="3.53"/>
</g>
<g class="marker interactive" data-index="2_1" data-image="crosshair">
  <circle class="cls-3" cx="604.71" cy="509.1" r="26.98"/>
  <path class="cls-2" d="M609.04 495.19l-2.87 2.86v-12.69h-3.03v12.59l-2.77-2.76-2.14 2.14 6.48 6.48 6.47-6.48-2.14-2.14zM600.37 523.01l2.87-2.86v12.69h3.03v-12.59l2.77 2.76 2.14-2.14-6.47-6.48-6.48 6.48 2.14 2.14zM618.62 513.43l-2.86-2.86h12.69v-3.03h-12.6l2.77-2.77-2.14-2.14-6.48 6.47 6.48 6.48 2.14-2.15zM590.79 504.77l2.86 2.86h-12.68v3.04h12.59l-2.77 2.76 2.15 2.15 6.47-6.48-6.47-6.47-2.15 2.14z"/>
  <circle class="cls-2" cx="604.71" cy="509.1" r="3.1"/>
</g>
<g class="">
  <circle class="cls-1" cx="1011.06" cy="496.4" r="26.98"/>
  <path class="cls-2" d="M1026.46 487.76c-.15-1-.35-2.16-2-2.16h-9.3l4.63-3.49-8.06-4.63-8.07 4.63 4.63 3.49h-13.92v1.82H1011a.66.66 0 01.74.74v5.18a.66.66 0 01-.74.74h-16.63v7.21h33.95zm-16.3-2.16l-4.45-3.33 6-3.43 6 3.43-4.45 3.33zm14.71 8.75h-4.4a.49.49 0 01-.56-.56V488a.5.5 0 01.56-.57h3.53c.26 0 .46.17.61.51l.79 5.75c.04.44-.15.66-.53.66zM994.37 504.15h8.07v4.91h-8.07zM1028.55 504.15h-7.62v4.92h8.07v-1.64l-.45-3.28z"/>
  <circle class="cls-2" cx="1007.42" cy="505.96" r="3.35"/>
  <circle class="cls-2" cx="1015.88" cy="505.93" r="3.35"/>
</g>
<g class="marker interactive" data-index="2_1" data-image="crosshair">
  <circle class="cls-3" cx="945.43" cy="496.4" r="26.98"/>
  <path class="cls-2" d="M949.76 482.49l-2.86 2.86v-12.69h-3.03v12.6l-2.77-2.77-2.14 2.14 6.47 6.48 6.48-6.48-2.15-2.14zM941.1 510.32l2.86-2.86v12.68H947v-12.59l2.76 2.77 2.15-2.15-6.48-6.47-6.47 6.47 2.14 2.15zM959.35 500.74l-2.87-2.87h12.69v-3.03h-12.59l2.77-2.77-2.15-2.14-6.48 6.47 6.48 6.48 2.15-2.14zM931.52 492.07l2.86 2.86h-12.69v3.04h12.59l-2.76 2.77 2.14 2.14 6.48-6.48-6.48-6.47-2.14 2.14z"/>
  <circle class="cls-2" cx="945.43" cy="496.4" r="3.1"/>
</g>
<g transform="translate(106, 196)">
  <g class="marker interactive" data-index="2_1" data-image="crosshair">
    <circle class="cls-3" cx="945.43" cy="496.4" r="26.98"/>
    <path class="cls-2" d="M949.76 482.49l-2.86 2.86v-12.69h-3.03v12.6l-2.77-2.77-2.14 2.14 6.47 6.48 6.48-6.48-2.15-2.14zM941.1 510.32l2.86-2.86v12.68H947v-12.59l2.76 2.77 2.15-2.15-6.48-6.47-6.47 6.47 2.14 2.15zM959.35 500.74l-2.87-2.87h12.69v-3.03h-12.59l2.77-2.77-2.15-2.14-6.48 6.47 6.48 6.48 2.15-2.14zM931.52 492.07l2.86 2.86h-12.69v3.04h12.59l-2.76 2.77 2.14 2.14 6.48-6.48-6.48-6.47-2.14 2.14z"/>
    <circle class="cls-2" cx="945.43" cy="496.4" r="3.1"/>
  </g>
</g>
<g transform="translate(-79, 196)">
  <g class="marker interactive" data-index="2_1" data-image="crosshair">
    <circle class="cls-3" cx="945.43" cy="496.4" r="26.98"/>
    <path class="cls-2" d="M949.76 482.49l-2.86 2.86v-12.69h-3.03v12.6l-2.77-2.77-2.14 2.14 6.47 6.48 6.48-6.48-2.15-2.14zM941.1 510.32l2.86-2.86v12.68H947v-12.59l2.76 2.77 2.15-2.15-6.48-6.47-6.47 6.47 2.14 2.15zM959.35 500.74l-2.87-2.87h12.69v-3.03h-12.59l2.77-2.77-2.15-2.14-6.48 6.47 6.48 6.48 2.15-2.14zM931.52 492.07l2.86 2.86h-12.69v3.04h12.59l-2.76 2.77 2.14 2.14 6.48-6.48-6.48-6.47-2.14 2.14z"/>
    <circle class="cls-2" cx="945.43" cy="496.4" r="3.1"/>
  </g>
</g>
<g class="">
  <circle class="cls-1" cx="1097.01" cy="200.62" r="26.98"/>
  <path class="cls-2" d="M1112.44 191.93c-.15-1-.35-2.16-2-2.16h-9.29l4.62-3.5-8.06-4.62-8.07 4.62 4.63 3.5h-13.88v1.82h16.59a.65.65 0 01.73.73v5.18a.65.65 0 01-.73.74h-16.59v7.22h34zm-16.29-2.16l-4.46-3.32 6-3.44 6 3.44-4.46 3.32zm14.7 8.76h-4.39a.5.5 0 01-.57-.56v-5.81a.5.5 0 01.57-.57h3.54c.26 0 .47.17.62.51l.79 5.75c.01.46-.18.68-.56.68zM1080.35 208.33h8.07v4.91h-8.07zM1114.53 208.33h-7.61v4.91h8.06v-1.63l-.45-3.28z"/>
  <circle class="cls-2" cx="1093.41" cy="210.13" r="3.35"/>
  <circle class="cls-2" cx="1101.86" cy="210.11" r="3.35"/>
</g>
<g class="marker interactive" data-index="2_1" data-image="crosshair">
  <circle class="cls-3" cx="1030.38" cy="200.62" r="26.98"/>
  <path class="cls-2" d="M1034.71 186.7l-2.86 2.87v-12.69h-3.04v12.59l-2.77-2.77-2.14 2.15 6.48 6.47 6.47-6.47-2.14-2.15zM1026.04 214.53l2.87-2.86v12.69h3.03v-12.6l2.77 2.77 2.14-2.14-6.47-6.48-6.48 6.48 2.14 2.14zM1044.29 204.95l-2.86-2.86h12.69v-3.04h-12.6l2.77-2.76-2.15-2.15-6.47 6.48 6.47 6.47 2.15-2.14zM1016.46 196.29l2.86 2.86h-12.69v3.03h12.6l-2.77 2.77 2.15 2.14 6.47-6.47-6.47-6.48-2.15 2.15z"/>
  <circle class="cls-2" cx="1030.38" cy="200.62" r="3.1"/>
</g>
<g transform="translate(487, -195)">
  <g class="marker interactive" data-index="2_1" data-image="crosshair">
    <circle class="cls-3" cx="945.43" cy="496.4" r="26.98"/>
    <path class="cls-2" d="M949.76 482.49l-2.86 2.86v-12.69h-3.03v12.6l-2.77-2.77-2.14 2.14 6.47 6.48 6.48-6.48-2.15-2.14zM941.1 510.32l2.86-2.86v12.68H947v-12.59l2.76 2.77 2.15-2.15-6.48-6.47-6.47 6.47 2.14 2.15zM959.35 500.74l-2.87-2.87h12.69v-3.03h-12.59l2.77-2.77-2.15-2.14-6.48 6.47 6.48 6.48 2.15-2.14zM931.52 492.07l2.86 2.86h-12.69v3.04h12.59l-2.76 2.77 2.14 2.14 6.48-6.48-6.48-6.47-2.14 2.14z"/>
    <circle class="cls-2" cx="945.43" cy="496.4" r="3.1"/>
  </g>
</g>
<g transform="translate(-123, 13)">
  <g class="marker interactive" data-index="2_1" data-image="crosshair">
    <circle class="cls-3" cx="945.43" cy="496.4" r="26.98"/>
    <path class="cls-2" d="M949.76 482.49l-2.86 2.86v-12.69h-3.03v12.6l-2.77-2.77-2.14 2.14 6.47 6.48 6.48-6.48-2.15-2.14zM941.1 510.32l2.86-2.86v12.68H947v-12.59l2.76 2.77 2.15-2.15-6.48-6.47-6.47 6.47 2.14 2.15zM959.35 500.74l-2.87-2.87h12.69v-3.03h-12.59l2.77-2.77-2.15-2.14-6.48 6.47 6.48 6.48 2.15-2.14zM931.52 492.07l2.86 2.86h-12.69v3.04h12.59l-2.76 2.77 2.14 2.14 6.48-6.48-6.48-6.47-2.14 2.14z"/>
    <circle class="cls-2" cx="945.43" cy="496.4" r="3.1"/>
  </g>
</g>
<g class="marker interactive" data-index="1_3" data-image="halfcircle">
  <circle class="cls-5" cx="822.37" cy="435.46" r="26.98"/>
  <circle class="cls-2" cx="821.92" cy="435.39" r="7.29" transform="rotate(-45 821.92 435.39)"/>
  <path class="cls-2" d="M810 444.2a4.57 4.57 0 00-1.14.17l-.26.07-.14-.22a15.87 15.87 0 01-2.5-6.6v-.14l-3.23.45v.13a19.16 19.16 0 003.06 8.06l.32.48-.08.19a4.34 4.34 0 104-2.59zM834.8 418.18a4.28 4.28 0 00-2.37.71l-.2.12-.19-.12a20.5 20.5 0 00-3.76-1.78 19.68 19.68 0 00-4.27-1h-.13l-.36 3.25h.14a16.27 16.27 0 013.55.8 16.76 16.76 0 013.12 1.48l.2.12v.4a1.66 1.66 0 000 .32 4.35 4.35 0 104.35-4.34zM820.72 416.07a20.75 20.75 0 00-4.21.74 19.94 19.94 0 00-4 1.69l-.38.21-.17-.08a4.27 4.27 0 00-1.89-.45 4.32 4.32 0 00-4 5.95l.07.18-.11.16a19.33 19.33 0 00-3.13 7.78v.13l3.22.53v-.13a16.38 16.38 0 012.3-6l.15-.24.26.09a4.28 4.28 0 005.61-4.14 4.08 4.08 0 00-.11-.95l-.06-.27.25-.13a17.09 17.09 0 013-1.22 16.24 16.24 0 013.51-.61h.13l-.2-3.27z"/>
</g>
<g class="marker interactive" data-index="1_2" data-image="halfcircle">
  <circle class="cls-5" cx="857.23" cy="206.75" r="26.98"/>
  <circle class="cls-2" cx="856.81" cy="206.8" r="7.29" transform="rotate(-45 856.82 206.8)"/>
  <path class="cls-2" d="M844.91 215.61a4 4 0 00-1.13.17l-.26.07-.15-.22a16 16 0 01-2.49-6.61v-.13l-3.24.45v.13a19 19 0 003.07 8.05l.32.49-.08.18a4.22 4.22 0 00-.39 1.77 4.35 4.35 0 104.35-4.35zM869.68 189.58a4.36 4.36 0 00-2.37.71l-.19.13-.2-.12a19.24 19.24 0 00-8-2.74h-.14l-.35 3.25h.13a16 16 0 013.55.8 15.61 15.61 0 013.12 1.48l.2.12v.72a4.35 4.35 0 104.35-4.35zM855.61 187.48a19.5 19.5 0 00-8.26 2.43l-.37.21-.17-.08a4.22 4.22 0 00-1.9-.46 4.35 4.35 0 00-4.35 4.35 4.27 4.27 0 00.32 1.6l.07.18-.11.16a19.66 19.66 0 00-3.13 7.79v.13l3.22.53v-.13a16.3 16.3 0 012.29-6l.15-.24.27.08a4 4 0 001.26.21 4.35 4.35 0 004.35-4.34 4.7 4.7 0 00-.11-1l-.07-.27.25-.13a15.66 15.66 0 013-1.22 16.87 16.87 0 013.52-.62h.16l-.21-3.26z"/>
</g>
<g transform="translate(-70 150)">
  <g class="marker interactive" data-index="1_1" data-image="halfcircle">
    <circle class="cls-5" cx="822.37" cy="435.46" r="26.98"/>
    <circle class="cls-2" cx="821.92" cy="435.39" r="7.29" transform="rotate(-45 821.92 435.39)"/>
    <path class="cls-2" d="M810 444.2a4.57 4.57 0 00-1.14.17l-.26.07-.14-.22a15.87 15.87 0 01-2.5-6.6v-.14l-3.23.45v.13a19.16 19.16 0 003.06 8.06l.32.48-.08.19a4.34 4.34 0 104-2.59zM834.8 418.18a4.28 4.28 0 00-2.37.71l-.2.12-.19-.12a20.5 20.5 0 00-3.76-1.78 19.68 19.68 0 00-4.27-1h-.13l-.36 3.25h.14a16.27 16.27 0 013.55.8 16.76 16.76 0 013.12 1.48l.2.12v.4a1.66 1.66 0 000 .32 4.35 4.35 0 104.35-4.34zM820.72 416.07a20.75 20.75 0 00-4.21.74 19.94 19.94 0 00-4 1.69l-.38.21-.17-.08a4.27 4.27 0 00-1.89-.45 4.32 4.32 0 00-4 5.95l.07.18-.11.16a19.33 19.33 0 00-3.13 7.78v.13l3.22.53v-.13a16.38 16.38 0 012.3-6l.15-.24.26.09a4.28 4.28 0 005.61-4.14 4.08 4.08 0 00-.11-.95l-.06-.27.25-.13a17.09 17.09 0 013-1.22 16.24 16.24 0 013.51-.61h.13l-.2-3.27z"/>
  </g>
</g>
<g class="marker interactive" data-index="1_1" data-image="halfcircle">
  <circle class="cls-5" cx="1160.57" cy="212.81" r="26.98"/>
  <circle class="cls-2" cx="1160.07" cy="212.73" r="8.1" transform="rotate(-45 1160.07 212.73)"/>
  <path class="cls-2" d="M1148.22 221.55a4.15 4.15 0 00-1.14.17l-.25.07-.15-.22a16 16 0 01-2.49-6.61v-.13l-3.24.45v.13a19.07 19.07 0 003.06 8.05l.33.49-.08.18a4.22 4.22 0 00-.39 1.77 4.35 4.35 0 104.35-4.35zM1173 195.52a4.36 4.36 0 00-2.37.71l-.19.13-.2-.12a19 19 0 00-3.76-1.78 19.6 19.6 0 00-4.26-1h-.14l-.35 3.25h.13a16.14 16.14 0 013.55.8 15.61 15.61 0 013.12 1.48l.2.12v.72a4.35 4.35 0 104.35-4.35zM1158.91 193.42a19.51 19.51 0 00-8.25 2.43l-.37.21-.18-.08a4.28 4.28 0 00-1.89-.46 4.35 4.35 0 00-4.35 4.35 4.27 4.27 0 00.32 1.6l.07.18-.11.16a19.72 19.72 0 00-3.13 7.79v.13l3.22.53v-.13a16.5 16.5 0 012.3-6l.15-.24.27.08a4 4 0 001.26.21 4.35 4.35 0 004.35-4.34 4.12 4.12 0 00-.12-1l-.06-.27.25-.13a15.66 15.66 0 013-1.22 16.89 16.89 0 013.51-.62h.14l-.21-3.26z"/>
</g>
<g transform="translate(-28 0)">
  <g class="marker interactive" data-index="4_1" data-image="right">
    <circle class="cls-6" cx="1197.96" cy="496.4" r="26.98"/>
    <path class="cls-2" d="M1206.53 486.14l-3.4 3.4 4.54 4.53h-28.54v4.81h28.39l-4.39 4.39 3.4 3.4 10.26-10.27-10.26-10.26z"/>
    <path class="cls-7" d="M1180.23 481.19h35.47v1.54h-35.47zM1180.23 510.08h35.47v1.54h-35.47z"/>
  </g>
</g>
<g class="marker interactive" data-index="4_2-3" data-image="right">
  <circle class="cls-6" cx="1137.97" cy="120.43" r="26.98"/>
  <path class="cls-2" d="M1146.53 110.16l-3.4 3.4 4.54 4.54h-28.53v4.81h28.38l-4.39 4.38 3.4 3.4 10.27-10.26-10.27-10.27z"/>
  <path class="cls-7" d="M1120.23 105.21h35.47v1.54h-35.47zM1120.23 134.1h35.47v1.54h-35.47z"/>
</g>
<g transform="translate(-50 250)">
  <g class="marker interactive" data-index="4_4" data-image="right">
    <circle class="cls-6" cx="1197.96" cy="496.4" r="26.98"/>
    <path class="cls-2" d="M1206.53 486.14l-3.4 3.4 4.54 4.53h-28.54v4.81h28.39l-4.39 4.39 3.4 3.4 10.26-10.27-10.26-10.26z"/>
    <path class="cls-7" d="M1180.23 481.19h35.47v1.54h-35.47zM1180.23 510.08h35.47v1.54h-35.47z"/>
  </g>
</g>
<g class="marker interactive" data-index="4_2-3" data-image="right">
  <circle class="cls-6" cx="1432.53" cy="241.55" r="26.98"/>
  <path class="cls-2" d="M1441.09 231.24l-3.4 3.4 4.53 4.53h-28.53v4.81h28.38l-4.38 4.39 3.4 3.4 10.26-10.27-10.26-10.26z"/>
  <path class="cls-7" d="M1414.78 226.29h35.47v1.54h-35.47zM1414.78 255.18h35.47v1.54h-35.47z"/>
</g>
<g class="marker interactive" data-index="3_1" data-image="tram">
  <circle cx="634.68" cy="621.25" r="26.98" fill="#04af28" stroke="#fff" stroke-miterlimit="10"/>
  <g id="_30x30" data-name="30x30">
    <path class="cls-2" d="M629.29 626.91a4.22 4.22 0 00-3.1 1.3 4.16 4.16 0 00-1.25 3 4.27 4.27 0 004.35 4.35 4.12 4.12 0 003.05-1.25 4.26 4.26 0 001.31-3.1 4.46 4.46 0 00-4.36-4.35zm0 6.65a2.3 2.3 0 112.3-2.3 2 2 0 01-2.3 2.3z"/>
    <path class="cls-2" d="M645.61 614.25a1.69 1.69 0 00-1-1.06 3.89 3.89 0 00-1.31-.4H618v1.56h5.41c.5 0 .75.21.75.74v7.21l-.15.4a.6.6 0 01-.61.35H618v9.7h4.6c.8 0 1.24-.43 1.31-1.3v-.2a5.39 5.39 0 015.35-5.41 5 5 0 013.75 1.61 5.08 5.08 0 011.61 3.8v.25c0 .8.43 1.22 1.29 1.25h11.82a.88.88 0 001-1v-7.15zm-11.56 8l-.15.4a.58.58 0 01-.61.35h-6.7c-.5 0-.75-.26-.75-.8v-7.1a.66.66 0 01.75-.75h6.7c.51 0 .75.22.75.75v7.2zm5.6 3.31h-3.3a.71.71 0 01-.8-.8v-9.66c0-.5.23-.75.7-.75h3.4a.66.66 0 01.75.75l.05 9.76c0 .5-.27.75-.8.75zm7.46-.5c0 .33-.25.5-.65.5h-3.71c-.53 0-.79-.25-.79-.75v-9.51c0-.67.44-1 1.34-1a.92.92 0 011 .75l2.9 9.51s-.06.22-.09.55z"/>
  </g>
  <path class="cls-9" d="M652.34 613h-1.26a8.5 8.5 0 00-8.48-8.48v-1.22a9.75 9.75 0 019.74 9.7z"/>
  <path class="cls-9" d="M648.2 612.57h-1.27c0-2.63-1.34-3.92-4.11-4v-1.26c3.48.08 5.38 1.93 5.38 5.26z"/>
</g>
<g class="marker interactive" data-index="5_2" data-image="leftstraight">
  <circle class="cls-10" cx="1233.69" cy="880.86" r="26.98"/>
  <path class="cls-2" d="M1245.7 898.92l2.54-2.12-10.98-13.14v-17.23l3.01 3.01 2.33-2.34-7.05-7.05-7.04 7.05 2.33 2.34 3.11-3.12v16.41h-14.94l3.01-3.01-2.33-2.34-7.05 7.05 7.05 7.05 2.33-2.34-3.11-3.11h16.02l10.77 12.89z"/>
</g>
<g class="marker interactive" data-index="5_2" data-image="leftstraight">
  <circle class="cls-10" cx="672.93" cy="121.93" r="26.98"/>
  <path class="cls-2" d="M684.95 139.99l2.53-2.11-10.98-13.15V107.5l3.01 3.01 2.33-2.33-7.05-7.05-7.04 7.05 2.33 2.33 3.12-3.11v16.4h-14.95l3.01-3.01-2.33-2.33-7.05 7.04 7.05 7.05 2.33-2.33-3.11-3.12h16.02l10.78 12.89z"/>
</g>
<g class="marker interactive" data-index="5_2" data-image="leftstraight">
  <circle class="cls-10" cx="1086.24" cy="87.11" r="26.98"/>
  <path class="cls-2" d="M1098.26 105.17l2.53-2.12-10.98-13.14V72.68l3.01 3.01 2.33-2.34-7.04-7.04-7.05 7.04 2.33 2.34 3.12-3.12v16.41h-14.95l3.02-3.01-2.34-2.34-7.05 7.05 7.05 7.05 2.34-2.34-3.12-3.11h16.02l10.78 12.89z"/>
</g>
<g class="marker interactive" data-index="5_1" data-image="leftstraight">
  <circle class="cls-10" cx="1076.7" cy="496.4" r="26.98"/>
  <path class="cls-2" d="M1088.71 514.47l2.53-2.12-10.98-13.14v-17.24l3.01 3.01 2.34-2.33-7.05-7.05-7.05 7.05 2.34 2.33 3.11-3.11v16.41h-14.94l3.01-3.02-2.34-2.33-7.04 7.05 7.04 7.05 2.34-2.34-3.12-3.11h16.03l10.77 12.89z"/>
</g>
<path fill="none" d="M0 0h1919.44v1080H0z"/>
</g>`;
export default iconsLayer;
