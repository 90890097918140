import { openBottomOverlay, closeBottomOverlay, bottomOverlay } from "./bottomOverlay";
import { flatendArr } from "./sidebar";

import iconsLayer from "./svgElements/iconsLayer";
const screenHeight = window.screen.height;
import foundationLayer from "../assets/images/layers/01-foundation.svg";
import gridLayer from "../assets/images/layers/02-grid.svg";
import landscapeLayer from "../assets/images/layers/03-landscape.svg";
import trainLineLayer from "../assets/images/layers/04-train-line.svg";
import redLayerUrl from "../assets/images/layers/red-heatmap.svg";
import yellowLayerUrl from "../assets/images/layers/yellow-heatmap.svg";

//Instantiate Map Object
const mapConfig = {
  crs: L.CRS.Simple,
  minZoom: 1,
  maxZoom: 2.7,
  zoom: 1,
  zoomSnap: 0.1,
  center: [0, 320],
  zoomControl: false,
  attributionControl: false,
};
const map = L.map("map-container", mapConfig);
// dimensions of the image
const w = 3840,
      h = 2160;

// calculate the edges of the image, in coordinate space
const southWest = map.unproject([0, h], map.getMaxZoom());
const northEast = map.unproject([w, 0], map.getMaxZoom());
let standardBounds = new L.LatLngBounds(southWest, northEast);

let maxBounds = new L.LatLngBounds(
  map.unproject([-50, h + 50], map.getMaxZoom()),
  map.unproject([w + 50, -50], map.getMaxZoom())
);

// Add Vector layers to the map
let layers = [foundationLayer, gridLayer, landscapeLayer, trainLineLayer];
let imageOverlays = [];
layers.forEach((imgUrl, index) => {
  if (index === 1) {
    imageOverlays.push(L.imageOverlay(imgUrl, standardBounds).addTo(map));
  } else {
    imageOverlays.push(L.imageOverlay(imgUrl, standardBounds).addTo(map));
  }
});

map.setMaxBounds(maxBounds);
const markersSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
markersSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
markersSvg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
markersSvg.setAttribute("viewBox", "0 0 1920 1080");
markersSvg.innerHTML = iconsLayer;

// let animationOverlay = L.svgOverlay(animSvg, imgBounds).addTo(map);
let markersOverlay = L.svgOverlay(markersSvg, standardBounds, { interactive: true }).addTo(map);

// map.fitBounds(imgBounds);
// map.setMaxBounds(imgBounds);

//Heatmap changing
var redHeatmapUI = document.querySelector("#heatmap1");
var greenHeatmapUI = document.querySelector("#heatmap2");
const redHeatmapName = document.querySelector("#heatmap-one-name");
const yellowHeatmapName = document.querySelector("#heatmap-two-name");

redHeatmapName.innerHTML = flatendArr["OVERLAY_1_SHORT_TITLE"];
yellowHeatmapName.innerHTML = flatendArr["OVERLAY_2_SHORT_TITLE"];

redHeatmapUI.addEventListener("change", redHeatmap);
greenHeatmapUI.addEventListener("change", greenHeatmap);
var redLayer = L.imageOverlay(redLayerUrl, standardBounds);
var yellowLayer = L.imageOverlay(yellowLayerUrl, standardBounds);
let OVERLAY1 = {
  title: flatendArr["OVERLAY_1_TITLE"],
  text: flatendArr["OVERLAY_1_TEXT"],
};
let OVERLAY2 = {
  title: flatendArr["OVERLAY_2_TITLE"],
  text: flatendArr["OVERLAY_2_TEXT"],
};
function redHeatmap(e) {
  openBottomOverlay(OVERLAY1);
  if (redHeatmapUI.checked) {
    greenHeatmapUI.checked = false;
    redLayer.addTo(map);
    map.removeLayer(yellowLayer);
    return;
  } else {
    redHeatmapUI.checked = false;
    bottomOverlay.classList.remove("open");
    map.removeLayer(redLayer);
  }
}
function greenHeatmap(e) {
  openBottomOverlay(OVERLAY2);
  if (greenHeatmapUI.checked) {
    redHeatmapUI.checked = false;
    yellowLayer.addTo(map);
    map.removeLayer(redLayer);
  } else {
    greenHeatmapUI.checked = false;
    bottomOverlay.classList.remove("open");
    map.removeLayer(yellowLayer);
  }
}

// Birds div icon
let birdsIcon = L.divIcon({
  html: `<div class="birds-container"><div class="birds"></div></div>`,
  className: "birds-div-icon",
  iconSize: [100, 100],
});
// L.marker([500, 800], { icon: birdsIcon }).addTo(map);

// Swans div icon
let swansIcon = L.divIcon({
  html: `<div class="swans-container"><div class="swans"></div></div>`,
  className: "swans-div-icon",
  iconSize: [48, 100],
  iconAnchor: [0, 0],
});

//Water div icon
let waterIcon = L.divIcon({
  html: `<div class="water-container" ><div class="water"></div></div>`,
  className: "water-div-icon",
  iconSize: [48, 70],
  iconAnchor: [0, 0],
});
// L.marker([183, 954], { icon: waterIcon }).addTo(map);
// L.marker([183, 953], { icon: swansIcon }).addTo(map);
// Zooming the map
map.on("zoomend", () => {
  console.log(`zoomed to ${map.getZoom()}`);
//   const swansIconUI = document.querySelector(".swans-div-icon");
//   const waterIconUI = document.querySelector(".water-div-icon");
//   const newWidth = `${47 * (map.getZoom() + 1)}px`;
//   const newHeight = `${100 * (map.getZoom() + 1)}px`;
//   const newWidthWater = `${47 * (map.getZoom() + 1)}px`;
//   const newHeightWater = `${70 * (map.getZoom() + 1)}px`;
//   swansIconUI.style.width = newWidth;
//   swansIconUI.style.height = newHeight;
//   waterIconUI.style.width = newWidthWater;
//   waterIconUI.style.height = newHeightWater;
  const birdsContainer = document.querySelector(".birds");
  birdsContainer.style.width = `${50 * (map.getZoom() + 1)}px`;
  birdsContainer.style.height = `${50 * (map.getZoom() + 1)}px`;
  const birds2Container = document.querySelector(".birds2");
  birds2Container.style.width = `${50 * (map.getZoom() + 1)}px`;
  birds2Container.style.height = `${50 * (map.getZoom() + 1)}px`;
});
export { map };
