import { flatendArr, openSidebar, setMarkerSidebar } from "./sidebar";

//GET ALL MARKERS ON THE MAP AND PROPERLY SET THEIR TRANSFORM-ORIGIN
window.onload = function () {
  const iconMarkers = document.querySelectorAll(".marker");
  iconMarkers.forEach((marker) => {
    const originX = marker.firstElementChild.getAttribute("cx");
    const originY = marker.firstElementChild.getAttribute("cy");
    marker.style.transformOrigin = `${originX}px ${originY}px`;
    const markerDATA = singleMarkerStrings(marker);

    if (markerDATA.title !== undefined) {
      marker.addEventListener("click", function (e) {
        setMarkerSidebar(markerDATA);
        openSidebar(e);
      });
    }
  });
};

function singleMarkerStrings(marker) {
  const dataIndex = marker.getAttribute("data-index");
  const dataImage = marker.getAttribute("data-image");
  let markerTitle;
  let markerDescr;
  let markerLinks;
  if (dataIndex !== null && dataIndex.length === 1) {
    markerTitle = flatendArr[`TITLE_${dataIndex}`];
    markerDescr = flatendArr[`LEGEND_${dataIndex}_TEXT`];
    markerLinks = [];
    for (let i = 0; i < 50; i++) {
      let linkText = flatendArr[`LEGEND_${dataIndex}_LINK_${i}_TEXT`];
      let linkURL = flatendArr[`LEGEND_${dataIndex}_LINK_${i}_URL`];
      if (linkURL && linkText) {
        markerLinks.push({ dataIndex: dataIndex, i: i, text: linkText, url: linkURL });
      }
    }
  } else {
    markerTitle = flatendArr[`POINT_${dataIndex}_TITLE`];
    markerDescr = flatendArr[`POINT_${dataIndex}_TEXT`];
    markerLinks = [];
    for (let i = 0; i < 50; i++) {
      let linkText = flatendArr[`POINT_${dataIndex}_LINK_${i}_TEXT`];
      let linkURL = flatendArr[`POINT_${dataIndex}_LINK_${i}_URL`];
      if (linkURL && linkText) {
        markerLinks.push({ dataIndex: dataIndex, i: i, text: linkText, url: linkURL });
      }
    }
  }
  const div = document.createElement("div");
  div.classList.add("single-marker-links-inner");
  markerLinks.forEach(function (el) {
    const link = document.createElement("a");
    link.classList.add("single-marker-single-link");
    link.href = el.url.replace(/<\/?p>/gi, "");
    link.target = "_top";
    link.innerHTML = el.text;
    div.appendChild(link);
  });
  return { title: markerTitle, desc: markerDescr, links: div, imgSrc: dataImage };
}
