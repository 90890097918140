const path1 = `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="150px"
width="100%" height="100%">
<style type="text/css">
	.st55{fill:none;stroke:none;stroke-miterlimit:10;}
</style>
<defs>
</defs>
	<path class="st55" id="path1" d="M 0.2 164.6 c 129.8 -46.9 307 -99.8 522.1 -132.1 c 119.3 -17.9 328.3 -47.8 599.8 -21.8 c 184.2 17.7 383.3 36.8 606 155.4 c 142.4 75.8 243.8 165.3 408.9 207.9"/>
	<path class="st55" id="path2" d="M 2150.9 0.9 c -272.7 -3.9 -494.7 19.3 -654.2 43.5 c -162.4 24.7 -300.1 56.4 -446 90.1 c -138.2 31.9 -209.1 48.5 -301.5 79.3 C 608.9 260.6 510.5 308.6 371.7 377 C 177 442 22 494 -358 545"/>
</svg>
`;
export default path1;
